import { TeacherStatus } from 'constants/teacherRecruitment';
import React, { useEffect, useState } from 'react';
import warningIcon from 'assets/svg/ic_alert_yellow.svg';
import closeIcon from 'assets/svg/ic_close_banner.svg';
import closeIconForBanner from 'assets/svg/ic_close.svg';
import logoOneClass from 'assets/images/red_logo.svg';
import { t } from 'utils/i18n';
import {
  BannerContainerStyles,
  ButtonLeftBannerReferenceStyles,
  ContainerBannerReferenceStyles,
  ImgCloseBannerStyles,
  ImgLogoStyles,
  ImgWarningStyles,
  LeftBannerReferenceStyles,
  TextBannerStyles,
  TextButtonBannerReferenceStyles,
  TextLeftBannerReferenceStyles,
  WrapperLeftBannerReferenceStyles,
  WrapperLeftWarningStyles,
} from 'layouts/dashboard/header/Header.styles';
import { useProfileService } from 'services/ProfileService';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'routes/routeConfig';

const Banner = () => {
  const { infoTeacherRecruitment, profile } = useProfileService();
  const navigate = useNavigate();

  const [
    isHiddenWarningTerminatedContract,
    setIsHiddenWarningTerminatedContract,
  ] = useState<boolean>(false);
  const [isHiddenBannerReferenceSystem, setIsBannerReferenceSystem] =
    useState<boolean>(false);

  const [isMount, setIsMount] = useState<boolean>(false);

  useEffect(() => {
    if (infoTeacherRecruitment?.sentTerminatedContractTime) {
      if (infoTeacherRecruitment?.signedTerminatedContractTime) {
        setIsHiddenWarningTerminatedContract(false);
      } else {
        setIsHiddenWarningTerminatedContract(true);
      }
    }
    if (profile?.status && profile?.status === TeacherStatus.UNAVAILABLE) {
      setIsBannerReferenceSystem(false);
    } else {
      if (profile?.status && profile?.status !== TeacherStatus.UNVERIFIED) {
        setIsBannerReferenceSystem(true);
      }
    }
  }, [infoTeacherRecruitment, isMount]);
  const handleGoToTerminatedContract = () => {
    navigate(PATH.TEACHER_TERMINATED_CONTRACT, { replace: true });
  };

  useEffect(() => {
    const body = document.body;
    const mainLayout = body.querySelector(
      '.showHiddenWarningBanner',
    ) as HTMLElement;

    if (
      (isHiddenWarningTerminatedContract && !isHiddenBannerReferenceSystem) ||
      (!isHiddenWarningTerminatedContract && isHiddenBannerReferenceSystem) ||
      (isHiddenWarningTerminatedContract && isHiddenBannerReferenceSystem)
    ) {
      mainLayout.style.paddingTop = '160px';
    } else {
      mainLayout.style.paddingTop = '116px';
    }
  }, [isHiddenWarningTerminatedContract, isHiddenBannerReferenceSystem]);

  const handleCloseWaringTerminatedContract = () => {
    setIsHiddenWarningTerminatedContract(false);
  };
  const handleCloseBannerReferenceSystem = () => {
    setIsBannerReferenceSystem(false);
  };
  const handleGoToReferenceSystem = () => {
    const urlReferenceSystem =
      import.meta.env.VITE_REFERENCE_SYSTEM_PAGE ||
      'https://reference-system.oneclass.com.tw/login';
    window.open(urlReferenceSystem, '_blank');
  };
  useEffect(() => {
    setIsMount(true);
  }, []);

  return (
    <>
      {isHiddenWarningTerminatedContract && (
        <BannerContainerStyles>
          <WrapperLeftWarningStyles onClick={handleGoToTerminatedContract}>
            <ImgWarningStyles src={warningIcon} />
            <TextBannerStyles>
              {t(
                'layouts.dashboard.header.banner.warningTerminatedContract',
                '您收到授課講師終止合約協議書，請盡快簽署',
              )}
            </TextBannerStyles>
          </WrapperLeftWarningStyles>

          <ImgWarningStyles
            src={closeIcon}
            onClick={handleCloseWaringTerminatedContract}
          />
        </BannerContainerStyles>
      )}
      {!isHiddenWarningTerminatedContract && isHiddenBannerReferenceSystem && (
        <ContainerBannerReferenceStyles>
          <WrapperLeftBannerReferenceStyles>
            <LeftBannerReferenceStyles>
              <ImgLogoStyles src={logoOneClass} />
              <TextLeftBannerReferenceStyles>
                {t(
                  'layouts.dashboard.header.banner.content',
                  '教師推薦系統上線！',
                )}
              </TextLeftBannerReferenceStyles>
            </LeftBannerReferenceStyles>

            <ButtonLeftBannerReferenceStyles
              onClick={handleGoToReferenceSystem}
            >
              <TextButtonBannerReferenceStyles>
                {t('layouts.dashboard.header.banner.buttonGoNow', '立即前往')}
              </TextButtonBannerReferenceStyles>
            </ButtonLeftBannerReferenceStyles>
          </WrapperLeftBannerReferenceStyles>

          <ImgCloseBannerStyles
            src={closeIconForBanner}
            onClick={handleCloseBannerReferenceSystem}
          />
        </ContainerBannerReferenceStyles>
      )}
    </>
  );
};

export default Banner;
