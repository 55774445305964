import { FC, ReactNode } from 'react';
import { SvgIconStyle, Mui, MuiLab } from '@onedesign/ui';
import { Breakpoint, styled, useTheme } from '@mui/material/styles';
import closeIcon from 'assets/svg/ic_close.svg';

const { LoadingButton } = MuiLab;

const {
  Box,
  Dialog: MuiDialog,
  DialogTitle: MuiDialogTitle,
  DialogContent: MuiDialogContent,
  DialogActions: MuiDialogActions,
  IconButton,
} = Mui;

const StyledDialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
}));

const StyledDialogActions = styled(MuiDialogActions)({
  padding: '13px !important',
  marginLeft: 'auto',
});

export type TButton = {
  text?: string;
  show?: boolean;
  disabled?: boolean;
  action: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  isLoading?: boolean;
  sx?: Mui.SxProps<Mui.Theme>;
};
export interface DialogProps {
  open: boolean;
  title: string | ReactNode;
  operateNode?: ReactNode;
  children?: ReactNode;
  close: TButton;
  disConfirm?: TButton;
  confirm?: TButton;
  width?: string;
  maxWidth?: Breakpoint | false;
  fullWidth?: boolean;
  bottomContent?: string;
}

export const Dialog: FC<DialogProps> = ({
  open,
  title,
  operateNode,
  children,
  close,
  disConfirm,
  confirm,
  width,
  maxWidth,
  fullWidth,
  bottomContent,
}) => {
  const theme = useTheme();

  return (
    <StyledDialog
      open={open}
      onClose={close?.action}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      scroll="body"
      disableEscapeKeyDown={true}
      PaperProps={{
        sx: {
          py: 1.5,
          width: width || '600px',
          [theme.breakpoints.down('sm')]: {
            width: 'auto',
          },
          overflow: 'hidden',
        },
      }}
    >
      <MuiDialogTitle
        sx={{
          p: 1.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ px: 1.5 }}>{title}</Box>
        <Box sx={{ ml: 1.5 }}>
          {operateNode}
          {close && close.text && close.show && (
            <IconButton
              aria-label="close"
              onClick={close.action}
              disabled={close.disabled || false}
              sx={{
                p: 1.5,
                color: theme => theme.palette.grey[500],
              }}
            >
              <SvgIconStyle src={closeIcon} />
            </IconButton>
          )}
        </Box>
      </MuiDialogTitle>
      {children && (
        <MuiDialogContent
          sx={{
            padding: '1rem 0.75rem !important',
            height: 'calc(100% - 72px - 62px)',
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'hidden',
          }}
        >
          <Box sx={{ px: 1.5, display: 'flex', flexDirection: 'column' }}>
            {children}
          </Box>
        </MuiDialogContent>
      )}
      {(disConfirm || confirm) && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {bottomContent && (
            <Box
              sx={{
                color: theme.palette.grey[600],
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '18px',
                paddingLeft: '24px',
                ...disConfirm?.sx,
              }}
            >
              {bottomContent}
            </Box>
          )}
          <StyledDialogActions>
            {disConfirm?.show && (
              <LoadingButton
                sx={{
                  color: theme.palette.grey[800],
                  borderColor: 'rgba(145, 158, 171, 0.32) !important',
                }}
                variant="outlined"
                onClick={disConfirm.action}
                disabled={disConfirm.disabled || false}
              >
                {disConfirm.text}
              </LoadingButton>
            )}
            {confirm && (
              <LoadingButton
                variant="contained"
                sx={confirm.sx || {}}
                color={confirm.color || 'primary'}
                onClick={confirm.action}
                disabled={confirm.disabled || false}
                loading={confirm.isLoading || false}
              >
                {confirm.text}
              </LoadingButton>
            )}
          </StyledDialogActions>
        </Box>
      )}
    </StyledDialog>
  );
};
