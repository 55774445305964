import { SvgIconStyle } from '@onedesign/ui';
import { SxProps } from '@mui/material';
import Book from 'assets/svg/ic_book.svg';
import BookOpen from 'assets/svg/book_open_solid.svg';
import BookReader from 'assets/svg/book_reader_solid.svg';
import Calendar from 'assets/svg/calendar_alt_solid.svg';
import ChartPie from 'assets/svg/chart_pie_solid.svg';
import CheckMark from 'assets/svg/ic_checkmark.svg';
import ChevronDown from 'assets/svg/ic_chevron_down.svg';
import ChevronLeft from 'assets/svg/ic_chevron_left.svg';
import ChevronRight from 'assets/svg/ic_chevron_right.svg';
import Close from 'assets/svg/ic_close.svg';
import Copy from 'assets/svg/copy_outline.svg';
import Download from 'assets/svg/ic_download.svg';
import Edit from 'assets/svg/ic_edit.svg';
import Error from 'assets/svg/ic_error.svg';
import Expand from 'assets/svg/expand_alt_solid.svg';
import EyeOff from 'assets/svg/ic_eye_base_off.svg';
import EyeOn from 'assets/svg/ic_eye_base_on.svg';
import Filter from 'assets/svg/ic_filter.svg';
import IdCard from 'assets/svg/id_card_solid.svg';
import Introduction from 'assets/svg/ic_introduction.svg';
import Link from 'assets/svg/ic_attach_file.svg';
import Minus from 'assets/svg/ic_minus.svg';
import Person from 'assets/svg/ic_person.svg';
import Plus from 'assets/svg/plus_outline.svg';
import Point from 'assets/svg/ic_point.svg';
import Search from 'assets/svg/ic_search.svg';
import Send from 'assets/svg/ic_send.svg';
import Settings from 'assets/svg/ic_settings.svg';
import SignIn from 'assets/svg/sign_in_alt_solid.svg';
import Trash from 'assets/svg/trash_alt_solid.svg';
import UserClock from 'assets/svg/user_clock_solid.svg';
import UserConfig from 'assets/svg/ic_user_cog_solid.svg';
import UserGroup from 'assets/svg/ic_user_group.svg';
import View from 'assets/svg/ic_eye.svg';
import Language from 'assets/svg/ic_language.svg';
import ExpandMore from 'assets/svg/ic_expand_more.svg';
import Suitcase from 'assets/svg/suitcase_solid.svg';
import Upload from 'assets/svg/ic_upload.svg';
import File from 'assets/svg/ic_file.svg';
import Youtube from 'assets/svg/ic_youtube.svg';
import CheckedCircle from 'assets/svg/ic_checked_circle_solid.svg';
import Plane from 'assets/svg/telegram_plane.svg';
import BullHorn from 'assets/svg/bullhorn_solid.svg';
import FileCheck from 'assets/svg/file-circle-check_solid.svg';
import IcRefresh from 'assets/svg/ic_refresh.svg';
import Alert from 'assets/svg/ic_alert.svg';
import RedError from 'assets/svg/ic_red_error.svg';
import MoreVertical from 'assets/svg/more_vertical_solid.svg';
import Notification from 'assets/svg/notification_on_solid.svg';
import Question from 'assets/svg/ic_question.svg';
import Arrow from 'assets/svg/ic_arrow.svg';
import PersonTie from 'assets/svg/user_tie_solid.svg';
import SeriesLesson from 'assets/svg/ic_series_lesson.svg';
import WhiteTrash from 'assets/svg/ic_white_trash.svg';
import Divider from 'assets/svg/divider.svg';
import Line from 'assets/svg/ic_line.svg';
import LineBackground from 'assets/svg/ic_line_background.svg';
import OneAnswer from 'assets/svg/one_answer.svg';
import PaperClip from 'assets/svg/ic_paper_clip.svg';
import PlayVideo from 'assets/svg/play_video.svg';
import RemovingAccountSetting from 'assets/svg/ic_removing_account_setting.svg';
import TrashThin from 'assets/svg/ic_trash_thin.svg';
import OneAnswerAi from 'assets/svg/icon_ai.svg';
import ContainedCopy from 'assets/svg/ic_copy.svg';
import Prev from 'assets/svg/ic_prev.svg';
import Next from 'assets/svg/ic_next.svg';
import Receipt from 'assets/svg/ic_receipt.svg';

const SVG = {
  Book,
  BookOpen,
  BookReader,
  Calendar,
  ChartPie,
  CheckMark,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Close,
  Copy,
  Download,
  Edit,
  Error,
  Expand,
  EyeOff,
  EyeOn,
  Filter,
  IdCard,
  Introduction,
  Link,
  Minus,
  Person,
  Plus,
  Point,
  Search,
  Send,
  Settings,
  SignIn,
  Trash,
  UserClock,
  UserConfig,
  UserGroup,
  View,
  Language,
  ExpandMore,
  Suitcase,
  Upload,
  File,
  Youtube,
  CheckedCircle,
  Plane,
  BullHorn,
  FileCheck,
  IcRefresh,
  Alert,
  RedError,
  MoreVertical,
  Notification,
  Question,
  Arrow,
  PersonTie,
  SeriesLesson,
  WhiteTrash,
  Divider,
  Line,
  LineBackground,
  OneAnswer,
  PaperClip,
  PlayVideo,
  RemovingAccountSetting,
  TrashThin,
  OneAnswerAi,
  ContainedCopy,
  Prev,
  Next,
  Receipt,
};

export enum SvgName {
  Book = 'Book',
  BookOpen = 'BookOpen',
  BookReader = 'BookReader',
  Calendar = 'Calendar',
  ChartPie = 'ChartPie',
  CheckMark = 'CheckMark',
  ChevronDown = 'ChevronDown',
  ChevronLeft = 'ChevronLeft',
  ChevronRight = 'ChevronRight',
  Close = 'Close',
  Copy = 'Copy',
  Download = 'Download',
  Edit = 'Edit',
  Error = 'Error',
  Expand = 'Expand',
  EyeOff = 'EyeOff',
  EyeOn = 'EyeOn',
  Filter = 'Filter',
  IdCard = 'IdCard',
  Introduction = 'Introduction',
  Link = 'Link',
  Minus = 'Minus',
  Person = 'Person',
  Plus = 'Plus',
  Point = 'Point',
  Search = 'Search',
  Send = 'Send',
  Settings = 'Settings',
  RemovingAccountSetting = 'RemovingAccountSetting',
  SignIn = 'SignIn',
  Trash = 'Trash',
  UserClock = 'UserClock',
  UserConfig = 'UserConfig',
  UserGroup = 'UserGroup',
  View = 'View',
  Language = 'Language',
  ExpandMore = 'ExpandMore',
  Suitcase = 'Suitcase',
  Upload = 'Upload',
  File = 'File',
  Youtube = 'Youtube',
  CheckedCircle = 'CheckedCircle',
  Plane = 'Plane',
  BullHorn = 'BullHorn',
  FileCheck = 'FileCheck',
  IcRefresh = 'IcRefresh',
  Alert = 'Alert',
  RedError = 'RedError',
  MoreVertical = 'MoreVertical',
  Notification = 'Notification',
  Question = 'Question',
  Arrow = 'Arrow',
  PersonTie = 'PersonTie',
  SeriesLesson = 'SeriesLesson',
  WhiteTrash = 'WhiteTrash',
  Divider = 'Divider',
  Line = 'Line',
  LineBackground = 'LineBackground',
  OneAnswer = 'OneAnswer',
  PaperClip = 'PaperClip',
  PlayVideo = 'PlayVideo',
  TrashThin = 'TrashThin',
  OneAnswerAi = 'OneAnswerAi',
  ContainedCopy = 'ContainedCopy',
  Prev = 'Prev',
  Next = 'Next',
  Receipt = 'Receipt',
}

type Props = {
  name: SvgName;
  sx?: SxProps;
};

export const Icon = ({
  name,
  sx = { width: '24px', height: '24px' },
}: Props) => {
  return <SvgIconStyle src={SVG[name]} sx={sx} />;
};
