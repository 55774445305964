import courseChange from 'assets/images/courseChange.svg';
import courseNotification from 'assets/images/courseNotification.svg';
import courseRemind from 'assets/images/courseRemind.svg';
import courseCancel from 'assets/images/courseCancel.svg';
import shiftRemind from 'assets/images/shiftRemind.svg';
import { t } from 'utils/i18n';
export const COURSE_ASK_STATUS = {
  cancel: {
    description: t(
      'constants.message.courseAskStatus.cancelDescription',
      '已取消',
    ),
    style: {
      fontWeight: '700',
      fontSize: '14px',
      padding: '10px',
      color: '#FFF7CD',
      background: '#FFF7CD',
      border: '1px solid #B78103',
      borderRadius: '0.5rem',
    },
  },
  unavailable: {
    description: t(
      'constants.message.courseAskStatus.unavailableDescription',
      '不排班',
    ),
    style: {
      fontWeight: '700',
      fontSize: '14px',
      padding: '10px',
      color: '#FFFFFF',
      background: '#FFAC82',
      borderRadius: '0.5rem',
    },
  },
  available: {
    description: t(
      'constants.message.courseAskStatus.availableDescription',
      '可排班',
    ),
    style: {
      fontWeight: '700',
      fontSize: '14px',
      padding: '10px',
      color: '#FFFFFF',
      background: '#74B71E',
      borderRadius: '0.5rem',
    },
  },
  scheduled: {
    description: t(
      'constants.message.courseAskStatus.scheduledDescription',
      '已排課',
    ),
    style: {
      fontWeight: '700',
      fontSize: '14px',
      padding: '10px',
      color: '#FFFFFF',
      background: '#74B71E',
      borderRadius: '0.5rem',
    },
  },
  waitFeedback: {
    description: t(
      'constants.message.courseAskStatus.waitFeedbackDescription',
      '待回覆',
    ),
    style: {
      fontWeight: '700',
      fontSize: '14px',
      padding: '10px',
      color: '#74B71E',
      border: ' 1px solid #74B71E',
      background: 'transparent',
      borderRadius: '8px',
    },
  },
  invalid: {
    description: t(
      'constants.message.courseAskStatus.invalidDescription',
      '已失效',
    ),
    style: {
      fontWeight: '700',
      fontSize: '14px',
      padding: '10px',
      color: '#637381',
      background: '#919EAB29',
      borderRadius: '0.5rem',
    },
  },
  reject: {
    description: t(
      'constants.message.courseAskStatus.rejectDescription',
      '已婉拒',
    ),
    style: {
      fontWeight: '700',
      fontSize: '14px',
      padding: '10px',
      color: '#FFFFFF',
      background: '#FF5630',
      borderRadius: '0.5rem',
    },
  },
} as const;
export enum INTERACTIVE_STATUS_NAME {
  cancel = '已取消',
  unavailable = '不排班',
  available = '可排班',
  scheduled = '已排課',
  waitFeedback = '待回覆',
  invalid = '已失效',
  reject = '已婉拒',
}

export enum INTERACTIVE_STATUS {
  unused = 'unused',
  cancel = 'cancel',
  unavailable = 'unavailable',
  available = 'available',
  scheduled = 'scheduled',
  waitFeedback = 'waitFeedback',
  invalid = 'invalid',
  reject = 'reject',
}

export enum ACCEPT_STATUS {
  unused = 'unused',
  choose = 'choose', // Be elected
  accept = 'accept', // Have read
  unaccept = 'unaccept', // unread
}

export enum READ_STATUS {
  READ = 'read',
  UNREAD = 'unread',
}

/**
 * @param Course_Change CourseChange Course Different Movement
 * @param Course_Cancel Coursecancel Course Cancel
 * @param Course_scheDule_reminder CourseScheDuleminder
 * @param Course_scheDule_notice CourseScheDulenotice
 * @param Course_reminder CourseMinder Reminder
 * @param Course_scheDule_Request CourseSChedulerequest
 * @param Text Text
 * @param Interactionice Interactive
 */
export enum MESSAGE_TYPE {
  courseReminder = 'courseReminder',
  courseScheduleReminder = 'courseScheduleReminder',
  courseScheduleNotice = 'courseScheduleNotice',
  courseChange = 'courseChange',
  courseCancel = 'courseCancel',
  batchCourseScheduleNotice = 'batchCourseScheduleNotice',
}

export enum ASK_MESSAGE_TYPE {
  courseScheduleRequest = 'courseScheduleRequest',
  batchCourseScheduleRequest = 'batchCourseScheduleRequest',
}

export const MESSAGE_TYPE_NAME = {
  [MESSAGE_TYPE.courseReminder]: () =>
    t('constants.message.messageType.courseReminder', '上課提醒'),
  [MESSAGE_TYPE.courseScheduleReminder]: () =>
    t('constants.message.messageType.courseScheduleReminder', '排班提醒'),
  [MESSAGE_TYPE.batchCourseScheduleNotice]: () =>
    t('constants.message.messageType.courseScheduleNotice', '排課通知'),
  [MESSAGE_TYPE.courseScheduleNotice]: () =>
    t('constants.message.messageType.courseScheduleNotice', '排課通知'),
  [MESSAGE_TYPE.courseChange]: () =>
    t('constants.message.messageType.courseChange', '課程異動'),
  [MESSAGE_TYPE.courseCancel]: () =>
    t('constants.message.messageType.courseCancel', '課程取消'),
} as const;

export const MESSAGE_TYPE_AVATAR = {
  [MESSAGE_TYPE.courseReminder]: courseRemind,
  [MESSAGE_TYPE.courseScheduleReminder]: shiftRemind,
  [MESSAGE_TYPE.batchCourseScheduleNotice]: courseNotification,
  [MESSAGE_TYPE.courseScheduleNotice]: courseNotification,
  [MESSAGE_TYPE.courseChange]: courseChange,
  [MESSAGE_TYPE.courseCancel]: courseCancel,
} as const;

export const ERROR_CODE = {
  COURSE_REMINDER: {
    INTERNAL_ERROR: '10200',
    NOT_EXIST: '10201',
    COURSE_NOT_EXIST: '10301',
  },
} as const;
